import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import gxIcon from '../assets/kiosk/gxMeter.svg'
import solarIcon from '../assets/kiosk/solar.svg'
import batteryIcon0 from '../assets/kiosk/battery0.svg'
import batteryIcon25 from '../assets/kiosk/battery25.svg'
import batteryIcon50 from '../assets/kiosk/battery50.svg'
import batteryIcon75 from '../assets/kiosk/battery75.svg'
import batteryIcon100 from '../assets/kiosk/battery100.svg'
import evWithoutCar from '../assets/kiosk/evWithoutCar.svg'
import evWithCar from '../assets/kiosk/evWithCar.svg'
import hvacIcon from '../assets/kiosk/hvac.svg'
import otherIcon from '../assets/kiosk/building.svg'
import arrow from '../assets/kiosk/arrow.svg'

const KioskModel = ({
    gxPower,
    hasSolar,
    solarPower,
    hasBattery,
    batterySoc,
    batteryDisplaySoc,
    batterySockWh,
    batteryPower,
    hasEv,
    hasEvConnected,
    evPower,
    hvacPower,
    otherPower
}) => {
    const [gxArrowClass, setGxArrowClass] = useState('arrow')
    const [solarArrowClass, setSolarArrowClass] = useState('arrow')
    const [batteryArrowClass, setBatteryArrowClass] = useState('arrow')

    let batteryIcon

    switch (batteryDisplaySoc) {
        case 0:
            batteryIcon = batteryIcon0
            break;
        case 25:
            batteryIcon = batteryIcon25
            break;
        case 50:
            batteryIcon = batteryIcon50
            break;
        case 75:
            batteryIcon = batteryIcon75
            break;
        case 100:
            batteryIcon = batteryIcon100
            break;
        default:
            batteryIcon = batteryIcon0
            break;
    }

    useEffect(() => {
        if (gxPower === 0) {
            setGxArrowClass('arrow inactive')
        } else if (gxPower > 0) {
            setGxArrowClass('arrow')
        } else if (gxPower < 0) {
            setGxArrowClass('arrow pointUp')
        }
    }, [gxPower])

    useEffect(() => {
        if (solarPower === 0) {
            setSolarArrowClass('arrow inactive')
        } else if (solarPower > 0) {
            setSolarArrowClass('arrow')
        } else if (solarPower < 0) {
            setSolarArrowClass('arrow pointUp')
        }
    }, [solarPower])

    useEffect(() => {
        if (batteryPower === 0) {
            setBatteryArrowClass('arrow inactive')
        } else if (batteryPower > 0) {
            setBatteryArrowClass('arrow pointUp')
        } else if (batteryPower < 0) {
            setBatteryArrowClass('arrow')
        }
    }, [batteryPower])
    
    return (
        <KioskModelDiv className='kiosk-container'>
                <div className="top-container">
                    <div className="gx container">
                        <div className={!gxArrowClass.includes('inactive') ? 'block active' : 'block inactive'}>
                            <img className="icon" src={gxIcon} alt="Grid Meter Icon" />
                            <p className="label">Grid</p>
                            <p className="value">{gxPower} kW</p>
                        </div>

                        <div className={gxArrowClass}>
                            <div className="arrow-container">
                                <div className="line"></div>
                                <i className="arrow-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="power-line">
                    <div className="dot"></div>
                    <div className="main-line"></div>
                    <div className="dot"></div>
                </div>

                <div className="bottom-container">
                    {hasSolar && (
                        <div className="solar container">
                            <div className={solarArrowClass}>
                                <div className="arrow-container">
                                    <div className="line"></div>
                                    <i className="arrow-icon"></i>
                                </div>
                            </div>
                            <div className={!solarArrowClass.includes('inactive') ? 'block active' : 'block inactive'}>
                                <img className="icon" src={solarIcon} alt="Solar Icon" />
                                <p className="label">Solar</p>
                                <p className="value">{solarPower} kW</p>
                            </div>
                        </div>
                    )}
                    

                    {hasBattery && (
                        <div className="battery container">
                            <div className={batteryArrowClass}>
                                <div className="arrow-container">
                                    <div className="line"></div>
                                    <i className="arrow-icon"></i>
                                </div>
                            </div>                
                            
                            <div className={batterySoc < 0 ? 'block inactive' : 'block active'}>
                                <img className="icon" src={batteryIcon} alt="Battery Icon" />
                                <p className="label">Battery</p>
                                <p className="value">{batteryPower} kW</p>
                                <p className="soc-value">{batterySoc} % / {batterySockWh} kWh</p>
                            </div>
                        </div> 
                    )}
                                

                    {hasEv && (
                        <div className="ev container">
                            <div className={evPower > 0 ? 'arrow' : 'arrow inactive'}>
                                <div className="arrow-container">
                                    <div className="line"></div>
                                    <i className="arrow-icon"></i>
                                </div>
                            </div>                
                            
                            <div className={evPower > 0 ? 'block active' : 'block inactive'}>
                                <img className="icon" src={hasEvConnected ? evWithCar : evWithoutCar} alt="EV Charger Icon" />
                                <p className="label">EV Charger</p>
                                <p className="value">{evPower} kW</p>
                            </div>
                        </div>
                    )}
                

                    <div className="hvac container">
                        <div className={hvacPower !== 0 && hvacPower !== null ? 'arrow' : 'arrow inactive'}>
                            <div className="arrow-container">
                                <div className="line"></div>
                                <i className="arrow-icon"></i>
                            </div>
                        </div>                
                        
                        <div className={hvacPower !== 0 && hvacPower !== null ? 'block active' : 'block inactive'}>
                            <img className="icon" src={hvacIcon} alt="HVAC Icon" />
                            <p className="label">HVAC</p>
                            <p className="value">{hvacPower} kW</p>
                        </div>
                    </div>


                    <div className="other container">
                        <div className={otherPower !== 0 && otherPower !== null ? 'arrow' : 'arrow inactive'}>
                            <div className="arrow-container">
                                <div className="line"></div>
                                <i className="arrow-icon"></i>
                            </div>
                        </div>                
                        
                        <div className={otherPower ? 'block active' : 'block inactive'}>
                            <img className="icon" src={otherIcon} alt="Building/Other Icon" />
                            <p className="label">Other</p>
                            <p className="value">{otherPower} kW</p>
                        </div>
                    </div>
                </div>
        </KioskModelDiv>
    )
}

const KioskModelDiv = styled.div`
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    max-width: 1200px;
    width: 100%;

    .power-line {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: -4px;
        width: calc(100% - 20px);
        .main-line {
            background: #00DC92;
            height: 4px;
            margin: 0;
            width: 100%;
        }
        
        .dot {
            background: #00DC92;
            border-radius: 50px;
            height: 10px;
            width: 10px;
        }
    }

    .bottom-container {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .block {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 12px 0;
            width: 170px;
    
            p {
                color: #02222B;
                margin: 0;
                &.label {
                    font-size: 18px;
                    font-weight: 500;
                    margin: 6px 0 0;
                }
                &.value {
                    font-size: 20px;
                    font-weight: 800;
                }
                &.soc-value {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            &.inactive {
                filter: opacity(0.5) grayscale(1);
            }
        }

        .arrow {
            &.pointUp {
                transform: rotate(180deg);
            }

            &.inactive {
                filter: opacity(0.25) grayscale(1);

                .arrow-container {
                    .line {
                        border: 2px dashed black;
                    }
                    .arrow-icon {
                        border-color: #02222B;
                    }
                }
            }

            .arrow-container {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin: 0 0 2px;
                position: relative;

                .arrow-icon {
                    border: solid #00DC92;
                    border-width: 0 3px 3px 0;
                    display: inline-block;
                    padding: 6px;
                    transform: rotate(45deg);
                }

                .line {
                    border: solid #00DC92;
                    border-radius: 50px;
                    border-width: 2px;
                    height: 40px;
                    margin-bottom: -14px;
                    position: sticky;
                }
            }
        }
    }

    img.icon {
        width: 50px;
    }

    @media screen and (max-width: 1024px) {
        .container .block {
            height: 55px;
            width: 55px;

            p {
                display: none;
            }
        }

        hr {
            width: calc(100% - 57px);
        }
    }

    @media screen and (max-width: 425px) {
        .container .block {
            height: 45px;
            width: 45px;

            img.icon {
                width: 35px;
            }
        }
    }
`

export default KioskModel